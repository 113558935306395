<template>
	<div class="c-site-footer">
		<footer
			id="footer"
			class="bg-theme text-white px-layout-margin py-xl/v z-10"
			:class="{ 'mb-4xl/v': lang === 'en' }"
		>
			<div
				v-if="footer.text"
				class="
					c-site-footer__text--en
					font-extralight
					text-teaser
					<768:relative <768:z-10
				"
				v-text="footer.text"
			></div>
			<div
				class="
					grid grid-cols-3
					<768:grid-cols-1
					gap-x-(1/4)/12col
					mt-4xl/v
					relative
				"
			>
				<div class="w-fit <768:hidden">
					<NuxtLinkExt :to="$store.state.site.url" aria-label="VIVE">
						<SvgViveLogo class="w-2xl/h" />
					</NuxtLinkExt>
					<ul
						v-if="footer.soMe && lang === 'en'"
						class="mt-md/v flex gap-x-5xs/h"
					>
						<li>
							<a
								v-if="footer.soMe.facebookUrl"
								:href="footer.soMe.facebookUrl"
								target="_blank"
								aria-label="VIVE på Facebook"
							>
								<SvgIconFacebook
									class="
										w-40
										h-40
										bg-theme-pattern
										hover:bg-theme-90
										transition-colors
										duration-200
										py-4xs/v
										rounded-full
									"
								/>
							</a>
						</li>
						<li>
							<a
								v-if="footer.soMe.instagramUrl"
								:href="footer.soMe.instagramUrl"
								target="_blank"
								aria-label="VIVE på Instagram"
							>
								<SvgIconInstagram
									class="
										w-40
										h-40
										bg-theme-pattern
										hover:bg-theme-90
										transition-colors
										duration-200
										py-4xs/v
										rounded-full
									"
								/>
							</a>
						</li>
						<li>
							<a
								v-if="footer.soMe.linkedInUrl"
								:href="footer.soMe.linkedInUrl"
								target="_blank"
								aria-label="VIVE på LinkedIn"
							>
								<SvgIconLinkedin
									class="
										w-40
										h-40
										bg-theme-pattern
										hover:bg-theme-90
										transition-colors
										duration-200
										py-4xs/v
										rounded-full
									"
								/>
							</a>
						</li>
						<li>
							<a
								v-if="footer.soMe.twitterUrl"
								:href="footer.soMe.twitterUrl"
								target="_blank"
								aria-label="VIVE på Twitter"
							>
								<SvgIconTwitter
									class="
										w-40
										h-40
										bg-theme-pattern
										hover:bg-theme-90
										transition-colors
										duration-200
										py-4xs/v
										rounded-full
									"
								/>
							</a>
						</li>
					</ul>
				</div>

				<!-- Newsletter - MOBILE -->
				<div
					v-if="footer.newsletter && footer.newsletter.signupUrl"
					class="
						c-site-footer__newsletter--mobile
						w-full
						mb-xl/v
						>=768:hidden
					"
				>
					<div class="w-full">
						<h2 v-if="footer.newsletter.title" class="font-bold">
							{{ footer.newsletter.title }}
						</h2>
						<p v-if="footer.newsletter.text">
							{{ footer.newsletter.text }}
						</p>

						<BaseButton
							:to="footer.newsletter.signupUrl.url"
							class="
								bg-transparent
								border border-white
								text-white
								mt-md/v
							"
							v-text="footer.newsletter.signupUrl.name"
						/>
					</div>
				</div>

				<div class="font-light">
					<!-- INFO -->
					<div
						v-if="footer.contact"
						:class="{
							'c-site-footer__contact-links--en': lang === 'en',
						}"
					>
						<div v-if="footer.contact.phone">
							<a :href="footer.contact.phone.link"
								>Tlf: {{ footer.contact.phone.text }}</a
							>
						</div>
						<div v-if="footer.contact.mail">
							<a :href="footer.contact.mail.link"
								>Mail: {{ footer.contact.mail.text }}</a
							>
						</div>
						<div v-if="footer.contact.ean">
							EAN: {{ footer.contact.ean }}
						</div>
						<div v-if="footer.contact.cvr">
							CVR: {{ footer.contact.cvr }}
						</div>
					</div>

					<!-- LINKS -->
					<ul
						v-if="footer.links && footer.links.length"
						class="mt-md/v"
					>
						<li
							v-for="link in footer.links"
							:key="link.id"
							class="underline"
						>
							<NuxtLinkExt :to="link.url">{{
								link.name
							}}</NuxtLinkExt>
						</li>
					</ul>

					<!-- SOME -->
					<ul
						v-if="footer.soMe && lang === 'da'"
						class="mt-md/v flex gap-x-5xs/h"
					>
						<li>
							<a
								v-if="footer.soMe.facebookUrl"
								:href="footer.soMe.facebookUrl"
								target="_blank"
								aria-label="VIVE på Facebook"
							>
								<SvgIconFacebook
									class="
										w-40
										h-40
										bg-theme-pattern
										hover:bg-theme-90
										transition-colors
										duration-200
										py-4xs/v
										rounded-full
									"
								/>
							</a>
						</li>
						<li>
							<a
								v-if="footer.soMe.instagramUrl"
								:href="footer.soMe.instagramUrl"
								target="_blank"
								aria-label="VIVE på Instagram"
							>
								<SvgIconInstagram
									class="
										w-40
										h-40
										bg-theme-pattern
										hover:bg-theme-90
										transition-colors
										duration-200
										py-4xs/v
										rounded-full
									"
								/>
							</a>
						</li>
						<li>
							<a
								v-if="footer.soMe.linkedInUrl"
								:href="footer.soMe.linkedInUrl"
								target="_blank"
								aria-label="VIVE på LinkedIn"
							>
								<SvgIconLinkedin
									class="
										w-40
										h-40
										bg-theme-pattern
										hover:bg-theme-90
										transition-colors
										duration-200
										py-4xs/v
										rounded-full
									"
								/>
							</a>
						</li>
						<li>
							<a
								v-if="footer.soMe.twitterUrl"
								:href="footer.soMe.twitterUrl"
								target="_blank"
								aria-label="VIVE på Twitter"
							>
								<SvgIconTwitter
									class="
										w-40
										h-40
										bg-theme-pattern
										hover:bg-theme-90
										transition-colors
										duration-200
										py-4xs/v
										rounded-full
									"
								/>
							</a>
						</li>
					</ul>
				</div>

				<!-- Newsletter - DESKTOP -->
				<div
					v-if="footer.newsletter && footer.newsletter.signupUrl"
					class="
						c-site-footer__newsletter
						flex flex-col
						justify-center
						<768:hidden
					"
				>
					<div class="w-3/12col">
						<h2 v-if="footer.newsletter.title" class="font-bold">
							{{ footer.newsletter.title }}
						</h2>
						<p v-if="footer.newsletter.text">
							{{ footer.newsletter.text }}
						</p>

						<BaseButton
							:to="footer.newsletter.signupUrl.url"
							class="
								bg-transparent
								border border-white
								text-white
								mt-md/v
								w-fit
							"
							v-text="footer.newsletter.signupUrl.name"
						/>
					</div>
				</div>

				<SkyAppear
					v-slot="{ intersectionRatio }"
					:options="{
						initState: 'outside',
						appearOnce: false,
						triggerEvents: ['resize', 'scroll'],
						thresholds: [0, 0.25, 0.5, 0.75, 1],
						delay: 'animationFrame',
					}"
				>
					<NuxtLinkExt
						:to="$store.state.site.url"
						aria-label="VIVE"
						class="c-site-footer__mobile-logo >=768:hidden mt-md/v"
						:style="{
							'--inset': `${
								Math.round((1 - intersectionRatio) * -3200) /
								100
							}%`,
						}"
					>
						<SvgViveLogoMobile class="w-full" />
					</NuxtLinkExt>
				</SkyAppear>
			</div>
		</footer>
	</div>
</template>

<script>
import { SkyAppear } from 'sky-appear';

/* I do this double import on purpose to avoid repeating the same ids! */
import SvgViveLogo from '~/assets/svgs/vive-logo.svg?inline';
import SvgViveLogoMobile from '~/assets/svgs/vive-logo.svg?inline&secondary';
import SvgIconFacebook from '~/assets/svgs/icon-facebook.svg?inline';
import SvgIconInstagram from '~/assets/svgs/icon-instagram.svg?inline';
import SvgIconLinkedin from '~/assets/svgs/icon-linkedin.svg?inline';
import SvgIconTwitter from '~/assets/svgs/icon-twitter.svg?inline';

export default {
	name: 'SiteFooter',
	components: {
		SkyAppear,
		SvgViveLogo,
		SvgViveLogoMobile,
		SvgIconFacebook,
		SvgIconInstagram,
		SvgIconLinkedin,
		SvgIconTwitter,
	},
	props: {},

	data() {
		const { footer } = this.$store.state.site;
		const lang =
			{
				11050: 'en',
			}[this.$store.state?.site?.cultureId] || 'da';

		return {
			footer,
			lang,
		};
	},
};
</script>

<style lang="postcss">
.c-site-footer {
	@apply relative isolate flex flex-col justify-center items-center overflow-hidden >=1441:overflow-visible print:hidden;

	&:before {
		@apply absolute bg-theme;
		content: '';
		left: calc(var(--margin, 0px) * -1);
		top: 0;
		width: calc(100% + var(--margin, 0px) * 2);
		height: 100%;
		z-index: -1;
	}
}

@screen >=1441 {
	.c-site-footer {
		--margin: (
				var(--visual-viewport-width, 100vw) -
					var(--theme-layout-max, 1440px)
			) / 2;
	}
}

.c-site-footer__newsletter {
	@apply relative flex justify-center items-center;

	&:before {
		@apply absolute rounded-full bg-theme-pattern;
		content: '';
		z-index: -1;
		width: auto;
		height: calc(100% + 2 * 40px);
		aspect-ratio: 1/1;
		transition: background-color 0.3s, transform 0.3s ease-in-out;
	}
}

@supports not selector(.c-site-footer__newsletter:has(.c-base-button:hover)) {
	.c-site-footer__newsletter:hover,
	.c-site-footer__newsletter:focus-within {
		&:before {
			@apply bg-theme-90;
			transform: scale(1.12);
		}
	}
}
@supports selector(.c-site-footer__newsletter:has(.c-base-button:hover)) {
	.c-site-footer__newsletter:has(.c-base-button:hover),
	.c-site-footer__newsletter:focus-within {
		&:before {
			@apply bg-theme-90;
			transform: scale(1.12);
		}
	}
}

.c-site-footer__newsletter--mobile {
	@apply relative flex justify-center items-center;

	&:before {
		@apply absolute rounded-full bg-theme-pattern transform translate-x-1/5;
		content: '';
		z-index: -1;
		width: 100%;
		height: auto;
		aspect-ratio: 1/1;
	}
}

.c-site-footer__mobile-logo path:nth-child(2) {
	translate: 0px var(--inset, -32%);
}

@screen <768 {
	.c-site-footer__text--en {
		@apply relative;
		&::before {
			@apply absolute bg-theme-90 rounded-full right-0 top-1/2;
			transform: translate(50%, -25%);
			z-index: -1;
			content: '';
			width: 450px;
			height: 450px;
		}
	}
}

@screen >=768 {
	.c-site-footer__contact-links--en {
		@apply relative w-fit;
		&::before {
			@apply absolute bg-theme-90 rounded-full transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2;
			z-index: -1;
			content: '';
			width: 475px;
			height: 475px;
		}
	}
}
</style>
